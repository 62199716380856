import React from "react";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";
import {ApiResponseError, apiResponseErrorCodes} from "../utils/api";
import FileChooseButton from "./FileChooseButton";

export default class ErrorView extends React.Component {

  state = {
    message: "",
  };

  componentDidMount() {
    const isProcessingTimeoutError = this.props.error && this.props.error.type === "processing_timeout";
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";
    const isPhotolabSystemError = isPhotolabError && this.props.error.code > -1000;

    let message = i18n.t("error__default_message");

    if (isPhotolabSystemError || isProcessingTimeoutError) {
      message = i18n.t("error_overload");
    } else if (isPhotolabError) {
      message = this.props.error.message;
    } else if (this.props.error instanceof ApiResponseError
      && this.props.error.code === apiResponseErrorCodes.FILE_FORMAT_INVALID)
    {
      message = i18n.t("error__api_code_415");
    }

    this.setState({message});
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const isPhotolabError = this.props.error && this.props.error.type === "photolab";
    const buttonText = isPhotolabError ? i18n.t("try_another_photo") : i18n.t("upload_choose_photo_button");

    return <main className="error-page">
      <div className="container">
        <div className="error-content">
          <img
            src={assetUrl(`assets/images/icon-error.png`)}
            alt="" />
          <h1 dangerouslySetInnerHTML={{__html: i18n.t("error_title")}} />
          <p>{this.state.message}</p>

          <pre>{JSON.stringify(this.props.error, null, 2)}</pre>
        </div>
        <FileChooseButton
          onFilesSelected={this.props.onFilesSelected}
          className="btn-upload-foto"
          children={buttonText} />
      </div>
    </main>;
  }
}
