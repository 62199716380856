/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {combo} from "./helpers";

function getGroups() {
  return [
    groups.newFace,
    groups.newSkin,
    groups.newHead,
    groups.noMask,
  ];
}

function createCombo(comboParams) {
  const templateParams = {
    canvas_template_name: comboParams.position[1],
    mask_template_name: comboParams.position[2],
    gender: "#0",
    seeds: comboParams.seed,
    prompt: comboParams.prompt,
    negative_prompt: comboParams.negativePrompt,
    strength: comboParams.strength,
    ...comboParams.templateParams,
  };

  if (comboParams.position[0] === groups.newFace) {
    templateParams.mask_blur = 0;
  }

  const steps = [
    {id: "gender_classifier"},
  ];

  const fileImages = [{src: "@" + comboParams.fileIndex}];

  ("" + comboParams.faceTemplateChain)
    .split(",")
    .map((i) => i.trim())
    .filter((i) => i.length > 0)
    .forEach((item, index) => {
      const step = {id: item};
      if (index === 0) {
        step.images = fileImages;
      }
      steps.push(step)
    });

  const mainTemplateStep = {id: comboParams.net[1], templateParams};
  if (steps.length === 1) {
    mainTemplateStep.images = fileImages;
  }

  steps.push(mainTemplateStep);

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + comboParams.seed + `,str:${comboParams.strength}`;
  const item = combo(steps);
  item.setGroup(comboParams.position[0]);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}

function getCreatives(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  ctrlnet,
  withStrengths,
  faceTemplateChain, 
  strengths
) {
  const results = [];
  // const strengths = [0.8, 0.75, 0.7, 0.6, 0.5, 0.4, 0.3, 0.25, 0.2];
  console.log(strengths);

  const positions = [
    [groups.newFace, 8659, 8810],
    [groups.newSkin, 8661, 8662],
    [groups.newHead, 8663, 8664],
    [groups.noMask, 8659, 8818],
  ];

  const nets = [
    ["ctrlnet", 8681],
    ["NO_ctrlnet", 8658],
  ];

  positions.forEach((position) => {
    nets.forEach((netItem) => {
      let params = [
        {inpainting_fill: 1, guessmode: "TRUE"},
        {inpainting_fill: 3, guessmode: "TRUE"},
        {inpainting_fill: 1, guessmode: "FALSE"},
        {inpainting_fill: 3, guessmode: "FALSE"},
      ];

      if (netItem[0] === "NO_ctrlnet") {
        params = [
          {inpainting_fill: 1},
          {inpainting_fill: 3},
        ];
      }

      params.forEach((paramsItem) => {
        strengths.forEach((strength) => {
          const combo = createCombo({
            fileIndex,
            position,
            net: netItem,
            seed: seeds.first(),
            prompt,
            negativePrompt,
            faceTemplateChain,
            strength,
            templateParams: paramsItem,
          });

          const paramsGroup = Object.keys(paramsItem)
            .map((k) => `${k}=${paramsItem[k]}`)
            .join(";");

          combo.setExtra("group_1", position[0]);
          combo.setExtra("group_2", netItem[0]);
          combo.setExtra("group_3", paramsGroup);

          results.push(combo);
        });
      });
    });
  });

  return results;
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(fileIndex, seeds, prompt, negativePrompt, ctrlnet, withStrengths, faceTemplateChain, strengths) {
    return getCreatives(fileIndex, seeds, prompt, negativePrompt, ctrlnet, withStrengths, faceTemplateChain, strengths);
  },
};
