import configGeneric from "./config-generic";

export function getProcessingConfig() {
  return configGeneric;
}

export function getProcessingGroups() {
  return getProcessingConfig().getGroups();
}

export function getCreativesConfigs(
  inputFileIndex,
  seeds,
  prompt,
  negativePrompt,
  ctrlnet,
  withStrengths,
  faceTemplateChain, 
  strengths
) {
  return getProcessingConfig().getCreatives(
    inputFileIndex,
    seeds,
    prompt,
    negativePrompt,
    ctrlnet,
    withStrengths,
    faceTemplateChain, 
    strengths
  );
}
