export default {
  COMMON: "common",
  newFace: "new_face",
  newSkin: "new_skin",
  newHead: "new_head",
  oldFace: "old_face",
  oldSkin: "old_skin",
  oldHead: "old_head",
  noMask: "no_mask",
};
