import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import processingManager from "../photolab/ProcessingManager";
import {ApiResponseError} from "../utils/api";
import {generatePath} from "react-router";
import ErrorView from "../components/ErrorView";
import uploadHandler from "../utils/upload.handler";

export default class UploadPage extends React.Component {

  state = {
    error: null,
    file: null,
  };

  componentDidMount() {
    const files = (this.props.location.state && this.props.location.state.files) || [];

    if (files && Array.isArray(files) && files.isNotEmpty()) {
      this.handleFiles(files);
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  }

  handleFiles = (files) => {
    const filesPromises = files.map((file) => {
      if (file instanceof File) {
        return this.uploadFile(file);
      } else {
        return file;
      }
    });

    Promise.all(filesPromises)
      .then((files) => {
        this.startProcessing(files);
      })
      .catch((error) => {
        this.setState({error}, this.context.hideLoader);
      })
  };

  uploadFile = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
    ];

    if (!allowedTypes.includes(file.type.toLowerCase())) {
      this.setState({
        error: new ApiResponseError({
          error_code: 415,
          error_message: i18n.t("error__api_code_415"),
        }),
      }, this.context.hideLoader);
      return;
    }

    return uploadHandler(file);
  };

  handleFilesSelected = (files) => {
    this.context.showLoader(true);

    this.setState({error: null}, () => {
      this.handleFiles(files);
    });
  };

  startProcessing = (files) => {
    processingManager.clear();

    this.context.setLoaderImages([
      files.random().url,
    ], () => {
      this.context.showLoader(true, () => {
        this.props.history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {
            files,
            seeds: this.props.location.state.seeds,
            prompt: this.props.location.state.prompt,
            negativePrompt: this.props.location.state.negativePrompt,
            withStrengths: this.props.location.state.withStrengths,
          },
        });
      });
    });
  };

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFilesSelected={this.handleFilesSelected}
      />;
    }

    return <React.Fragment />;
  }
}

UploadPage.contextType = AppContext;